<template>
  <div>
    <v-row>
      <v-col
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        class="d-flex flex-column flex-md-row justify-center align-stretch"
      >
        <payment-card
          :payment-method="paymentMethod"
          @open-payment-form="openPaymentForm(paymentMethod)"
        />
      </v-col>
    </v-row>
    <payment-form
      v-if="selectedPaymentMethod"
      :is-shown="paymentFormIsShown"
      :payment-method="selectedPaymentMethod"
      @close="closePaymentForm()"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import PaymentCard from '@/views/AddFunds/PaymentMethods/PaymentCard.vue'
  import PaymentForm from '@/views/AddFunds/PaymentMethods/PaymentForm/Index.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'PaymentMethods',
    components: {
      PaymentForm,
      PaymentCard
    },
    data() {
      return {
        paymentFormIsShown: false,
        selectedPaymentMethod: null
      }
    },
    computed: {
      ...mapState('settings', ['paymentMethods'])
    },
    methods: {
      closePaymentForm() {
        this.paymentFormIsShown = false
        this.selectedPaymentMethod = null
      },
      openPaymentForm(paymentMethod) {
        this.selectedPaymentMethod = paymentMethod
        gtmPush({
          event: paymentMethod.type,
          event_category: GTM_EVENTS_CATEGORY.PAYMENT
        })
        this.paymentFormIsShown = true
      }
    }
  }
</script>
